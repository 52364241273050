<template>
	<div>
		<Navbar></Navbar>

		<div class="container" style="padding:1em">
			<div class="columns is-centered">
				<div class="column">
					<aside class="menu">
						<form @submit.prevent="chargerClient">
							<div class="field">
								<label class="label">Email ou code client</label>
								<div class="control">
									<input v-model="identifiant" class="input is-primary" type="text" placeholder="Email ou Code Client">
								</div>
							</div>
							<div class="field" v-if="avecMotDePasse">
								<label class="label">Mot de passe</label>
								<div class="control">
									<input v-model="password" class="input is-primary" type="test" placeholder="Mot de passe">
								</div>
								<button type="button" class="button is-small is-text" @click="avecMotDePasse=false">Masquer le champ "mot de passe"</button>
							</div>

							<Message :message="message"></Message>
							<div class="field is-grouped">
								<div class="control">
									<button type="submit" class="button is-small is-link" :class="loading ? 'is-loading' : ''">Rechercher</button>
								</div>
								<div class="control">
									<button type="button" @click="reset()" class="button is-link is-small is-light">Effacer</button>
								</div>
							</div>
							<button type="button" v-if="!avecMotDePasse" @click="avecMotDePasse=true" class="button is-small is-text">Tester le mot de passe</button>
						</form>
					</aside>
					<br><br>
					<div class="card">
						<div class="card-content">
							<h2 class="title is-6">Accès wildcard</h2>
							<p>Pour créer un accès wildcard qui donne un accès à tous les titres, utiliser un couple identifiant + mot de passe de cette manière : </p>

							<p>
								<b>Identifiant</b><br>
								<code><b>nimportequoi</b>@canardpc.com</code><br>
								<small>Cette adresse n'a pas besoin d'exister</small>
							</p>
							<p>
								<b>Mot de passe</b><br>
								<code>Nimportequoi!</code><br>
								<small>(Majuscule au début, ! à la fin)</small>
							</p>
						</div>
					</div>
				</div>
				<div class="column is-9">
					<template v-if="ready">
						<template v-if="client">

							<h3 v-if="clients.length>1" class="subtitle is-6">{{clients.length}} fiches clients correspondent à cet identifiant</h3>
							<template v-for="client in clients">
								<Client :client="client"></Client>
							</template>

							<section class="section">
								<div class="card">
									<div class="card-content">
										<p class="title is-4">Abonnements</p>
										<p class="subtitle is-6">{{abonnements.length}} abonnement(s)</p>
										<template v-for="abonnement in abonnements">
											<Abonnement :abonnement="abonnement"></Abonnement>
										</template>
									</div>
								</div>
							</section>
							<center>
								<a :href="api">Url de l'api pour ce client</a>
							</center>
						</template>
					</template>
				</div>
			</div>
		</div>		
	</div>
</template>

<script>

	export default {
		components: {
		},
		data : ()=>{
			return {
				loading:false,
				ready:false,
				message:'',
				identifiant : '',
				password : '',
				client:false,
				clients:[],
				abonnements:{},
				avecMotDePasse:false,
				api:''
			}
		},
		watch:{
			$route (to, from){
				//this.reset()
			}
		},    
		mounted() {
			if(this.$route.query.identifiant) {
				this.identifiant = this.$route.query.identifiant;
				this.chargerClient();
			}
		},
		methods : {
			reset() {
				this.identifiant='';
				this.password='';
				this.avecMotDePasse=false;
				this.message='';
				this.client={};
				this.ready=false
			},
			chargerClient() {
				this.loading=true;
				this.message='';
				this.api='';
				if(this.$route.query.identifiant != this.identifiant) {
					this.$router.push({ path: '/', query: { identifiant: this.identifiant }});
				}
				let params = {
					verbose:true,
					identifiant:this.identifiant
				};
				if(this.avecMotDePasse && this.password) {
					params.pass=this.password;
				}
				axios.post('testeur',params).then(response => {
					this.loading=false;
					if(response.data.message) {
						this.ready=false;
						this.client=false;
						if(this.identifiant.includes('@canardpc.com')) {
							this.avecMotDePasse=true;
						} else {
							this.message = response.data.message;
						}
					} else {
						this.ready=true;
						this.client = response.data.debug.client || true;
						this.clients = response.data.debug.clients || [this.client];
						this.api = response.data.debug.api;
						this.abonnements = response.data.abonnements;
					}
				})
			}
		}
	}
</script>
<style lang="scss">
a.card {
	display: block;
	transition: background-color .5s ease;
	&:hover {
		background-color: rgba(0, 50, 50, 0.2)
	}
}

blockquote {
	font-size: smaller;
}
</style>